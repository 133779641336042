.table-emails.table {
  border-collapse: separate;
  border-spacing: 0 .5rem; }
  .table-emails.table thead tr {
    font-size: .875rem;
    color: #979797; }
  .table-emails.table tbody tr {
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.05);
    background-color: #fff; }
    .table-emails.table tbody tr td {
      padding: 1rem; }

.label-status {
  border-radius: 23.5px;
  color: #fff;
  width: 8.75rem;
  padding: 0.25rem;
  text-align: center; }

.emails-mobile {
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.05); }
  .emails-mobile .email-item {
    background-color: #fff;
    font-size: 0.75rem; }

.estimate-detail-history {
  margin-top: 3rem; }
  .estimate-detail-history .title {
    font-size: 14px;
    color: #171E23;
    letter-spacing: 0;
    line-height: 22.4px;
    margin-bottom: 5px;
    font-weight: 600; }
  .estimate-detail-history .payment {
    background: #FFFFFF;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-bottom: 1rem; }
    .estimate-detail-history .payment .payment-info {
      padding: 1rem; }
      @media screen and (min-width: 768px) {
        .estimate-detail-history .payment .payment-info {
          flex-wrap: nowrap; } }
      .estimate-detail-history .payment .payment-info .topSection {
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px; }
        .estimate-detail-history .payment .payment-info .topSection .tip {
          font-size: 24px !important;
          font-weight: 300 !important; }
        .estimate-detail-history .payment .payment-info .topSection .boldInfo {
          font-size: 18px;
          color: #171E23;
          letter-spacing: 0;
          line-height: 22px;
          font-weight: 600; }
        .estimate-detail-history .payment .payment-info .topSection .lightInfo {
          font-size: 14px;
          color: #A2A2A2;
          letter-spacing: 0;
          line-height: 20px; }
      .estimate-detail-history .payment .payment-info .lineSplit {
        height: 2px;
        width: 100%;
        background-color: #F9F9F9; }
      .estimate-detail-history .payment .payment-info .bottomSection {
        padding-top: 1rem;
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px; }
      .estimate-detail-history .payment .payment-info .btn-view-receipt {
        color: #307FE2;
        text-align: center;
        padding-top: 1rem;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: bold; }
        .estimate-detail-history .payment .payment-info .btn-view-receipt img {
          margin-left: 1rem; }
