.record-item {
  display: none;
  width: 100%;
  padding: 0rem 2rem; }
  @media screen and (max-width: 992px) {
    .record-item {
      display: inline-block; } }
  .record-item .nameContent .name {
    font-weight: 700;
    size: 16px;
    line-height: 24px;
    color: #2a2a2a; }
  .record-item .nameContent .total {
    font-weight: bold;
    size: 16px;
    line-height: 24px;
    color: #2a2a2a;
    margin: 0; }

.large::before {
  width: 1.5rem !important;
  height: 1.5rem !important; }

.large::after {
  width: 1.5rem !important;
  height: 1.5rem !important; }

.large::after {
  width: 1.5rem !important;
  height: 1.5rem !important; }

.small::before {
  width: 5rem !important;
  height: 1rem !important; }

.small::after {
  width: 1rem !important;
  height: 1rem !important; }
