.subscriptions .title {
  font-size: 1.25rem;
  font-weight: bold;
  text-transform: uppercase; }

.subscriptions .alert {
  color: #ffffff; }
  .subscriptions .alert img {
    margin-right: 1.25rem; }

.subscriptions .alert-danger {
  background-color: #D0021B; }

.subscriptions .alert-warning {
  background-color: #FFBE14; }

.subscriptions .alert-info {
  background-color: #307FE2; }

.subscriptions .alert-href {
  font-weight: bold;
  text-decoration: underline !important; }

.subscriptions .subscriptions-title {
  text-align: center;
  font-size: 1.25rem;
  margin: 0;
  padding: 2.875rem 0; }

.subscriptions .payment-info {
  padding: 1.25rem 0; }
  .subscriptions .payment-info .sub-title {
    font-size: .875rem;
    color: #2A2A2A;
    font-weight: bold; }
  .subscriptions .payment-info .sub {
    color: #929292;
    padding: 1rem 0;
    font-size: .875rem; }
  .subscriptions .payment-info .btn-add-payment-method {
    width: 100%;
    text-transform: uppercase;
    height: 3.5rem;
    font-size: 0.75rem;
    font-weight: bold; }
