.credit-card-dropdown--wrapper .accordion {
  border-bottom: 1px solid #ECECEC; }

.credit-card-dropdown--wrapper .card {
  background: none;
  border: none;
  border-top: 1px solid #ECECEC; }

.credit-card-dropdown--wrapper .card-header {
  background: none;
  border-bottom: none;
  padding: 0; }
  .credit-card-dropdown--wrapper .card-header .fakeButton {
    position: relative;
    width: 100%;
    padding: 15px 25px 15px 0px !important;
    text-align: left;
    font-weight: bold; }
  .credit-card-dropdown--wrapper .card-header .carret {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #007bff; }
    .credit-card-dropdown--wrapper .card-header .carret i {
      transform: rotate(0);
      transition: transform 0.2s; }
  .credit-card-dropdown--wrapper .card-header .carret--active i {
    transform: rotate(180deg);
    transition: transform 0.2s; }

.credit-card-dropdown--wrapper .card-body {
  padding: 0; }

.credit-card-dropdown--wrapper .card-body--wrapper {
  padding: 5px 25px 10px 25px; }
  .credit-card-dropdown--wrapper .card-body--wrapper .signature {
    margin: 15px 0; }
  .credit-card-dropdown--wrapper .card-body--wrapper fieldset {
    height: 100%; }
  .credit-card-dropdown--wrapper .card-body--wrapper .fieldset--content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; }
    .credit-card-dropdown--wrapper .card-body--wrapper .fieldset--content .subtitle {
      flex-grow: 1; }

.credit-card-dropdown--wrapper .description {
  font-size: 0.75rem;
  color: #828282; }

.credit-card-dropdown--wrapper .discount {
  color: #212529;
  font-size: 1rem; }

.credit-card-dropdown--wrapper .title,
.credit-card-dropdown--wrapper .subtitle {
  margin-bottom: 5px; }
  @media screen and (min-width: 768px) {
    .credit-card-dropdown--wrapper .title,
    .credit-card-dropdown--wrapper .subtitle {
      margin-bottom: 10px; } }
