.btn-disconnect {
  position: absolute;
  right: -3.5rem;
  top: 2rem;
  border: none; }
  .btn-disconnect:hover {
    background-color: white; }
  .btn-disconnect:focus {
    background-color: white; }

.btn-connect {
  position: absolute;
  right: -2.5rem;
  top: 2rem;
  border: none; }
  .btn-connect:hover {
    background-color: white; }
  .btn-connect:focus {
    background-color: white; }
