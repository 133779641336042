.brief {
  position: relative;
  padding-right: 16px;
  line-height: 20px; }
  @media screen and (min-width: 1000px) {
    .brief {
      max-width: 56%; } }
  .brief img {
    position: absolute;
    top: 3px;
    right: 0; }

.mustUpgrade {
  position: relative;
  background: #E6F4E6;
  border-radius: 4px;
  padding: 11px 16px;
  margin: 20px 0; }
  @media screen and (min-width: 768px) {
    .mustUpgrade {
      max-width: 60%; } }
  .mustUpgrade p {
    font-size: 14px;
    line-height: 18px;
    margin: 0;
    color: #343F46; }
  .mustUpgrade strong {
    color: #307FE2;
    cursor: pointer; }
  .mustUpgrade img {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%); }

.videoContainer {
  margin: 50px auto;
  text-align: center; }
  .videoContainer .videoWrapper {
    max-width: 800px;
    margin: 0 auto; }
  .videoContainer img {
    max-width: 100%; }

.modal-confirm-custom {
  text-align: center;
  margin: 0;
  z-index: 2000; }
  @media (min-width: 576px) {
    .modal-confirm-custom {
      max-width: 500px;
      margin: 0 !important; } }
  .modal-confirm-custom .modal-content {
    border: none; }
    .modal-confirm-custom .modal-content .modal-header {
      display: block; }
  .modal-confirm-custom .closeIcon {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 99;
    cursor: pointer; }
  .modal-confirm-custom .title {
    margin-top: 1rem;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px; }
  .modal-confirm-custom .modal-footer {
    display: block;
    border-top: unset !important; }
    .modal-confirm-custom .modal-footer div {
      justify-content: space-evenly; }
      .modal-confirm-custom .modal-footer div button {
        width: 120px; }

.assign-to-modal {
  margin: 0; }
  @media (min-width: 576px) {
    .assign-to-modal {
      max-width: 500px;
      margin: 0 !important; } }
  .assign-to-modal .modal-body .content {
    height: 30vh;
    overflow: auto; }
  .assign-to-modal .modal-footer {
    display: block;
    border-top: unset !important; }
    .assign-to-modal .modal-footer div {
      justify-content: space-evenly; }
      .assign-to-modal .modal-footer div button {
        width: 120px; }

.workflow-services-detail {
  padding-right: 40px; }
  @media screen and (max-width: 576px) {
    .workflow-services-detail {
      padding-right: 20px; } }

.checklist-item-size {
  max-width: 180px; }
  @media screen and (min-width: 480px) and (max-width: 1280px) {
    .checklist-item-size {
      max-width: 144px; } }

.workflow-services-inner {
  position: relative;
  padding-right: 50px; }
  .workflow-services-inner .workflow-services-icons {
    position: absolute;
    top: 0;
    right: 0; }
    .workflow-services-inner .workflow-services-icons img + img {
      margin-left: 8px; }

.edit-time-modal {
  margin: 0; }
  @media (min-width: 576px) {
    .edit-time-modal {
      max-width: 500px;
      margin: 0 !important; } }
  .edit-time-modal .modal-header {
    text-align: center; }
  .edit-time-modal .modal-body {
    padding-top: 48px;
    padding-bottom: 32px; }
    .edit-time-modal .modal-body .edit-time-row {
      padding-top: 8px;
      padding-right: 0px;
      padding-left: 60px; }
      @media screen and (max-width: 420px) {
        .edit-time-modal .modal-body .edit-time-row {
          padding-right: 40px;
          padding-left: 0px; } }
    .edit-time-modal .modal-body .edit-time-contain {
      display: flex; }
      .edit-time-modal .modal-body .edit-time-contain .edit-time-input {
        font-size: 26px;
        text-align: right;
        border: none; }
      .edit-time-modal .modal-body .edit-time-contain .edit-time-text {
        padding-top: 12px;
        color: gray; }
        @media screen and (max-width: 1280px) {
          .edit-time-modal .modal-body .edit-time-contain .edit-time-text {
            padding-top: 14px; } }
        @media screen and (max-width: 420px) {
          .edit-time-modal .modal-body .edit-time-contain .edit-time-text {
            padding-top: 16px; } }
  .edit-time-modal .modal-footer {
    display: block;
    border-top: unset !important; }
    .edit-time-modal .modal-footer div {
      justify-content: space-evenly; }
      .edit-time-modal .modal-footer div button {
        width: 120px; }

.container-workflow {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media screen and (min-width: 1280px) and (max-width: 1380px) {
    .container-workflow {
      max-width: 1200px; } }
  @media screen and (min-width: 1380px) and (max-width: 1400px) {
    .container-workflow {
      max-width: 1350px; } }
  @media screen and (min-width: 1400px) {
    .container-workflow {
      max-width: 1400px; } }
