.tooltip-erac {
  position: relative;
  display: inline-block;
  margin-left: -2.5rem;
  margin-right: -2.5rem; }
  .tooltip-erac .tooltiptext {
    background-color: #fff;
    color: #000;
    max-width: 22rem;
    padding: 1.7rem;
    text-align: left;
    border-radius: 0.25rem;
    position: absolute;
    z-index: 1;
    bottom: 3.5rem;
    left: 6rem;
    margin-left: -4rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }
    .tooltip-erac .tooltiptext h5 {
      display: inline-block;
      font-weight: 600;
      margin-left: -2rem; }
    .tooltip-erac .tooltiptext p {
      margin: 0 -2.5rem 1rem; }
    .tooltip-erac .tooltiptext::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 1rem 1rem 0;
      border-style: solid;
      border-color: #fff transparent transparent transparent; }
