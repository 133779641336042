.analytics-edit {
  padding: 2px;
  margin: 4px;
  width: 24px;
  height: 24px;
  border: 0;
  background-image: url("/assets/images/reporting/Edit.svg");
  background-repeat: no-repeat;
  background-color: #ffffff; }

.btn-run {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  width: 123px;
  height: 40px;
  background-color: #307fe2;
  border-radius: 4px;
  border-width: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff; }

.switch-graph {
  display: flex;
  flex-direction: row;
  align-items: center;
  isolation: isolate;
  width: 182px;
  height: 42px;
  background: #e2e8ee;
  border: 5px solid #cad2d9;
  border-radius: 24px;
  flex: none;
  flex-grow: 0; }

.slider-line-on {
  /* Left */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 86px;
  height: 32px;
  background: #307fe2;
  border-radius: 24px 0px 0px 24px;
  border-color: #307fe2;
  border-width: 0;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
  /* Text */
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff; }

.slider-line-off {
  /* Left */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 86px;
  height: 32px;
  /* Fill/Grayscale/Gray 50 */
  background: #e2e8ee;
  border-radius: 24px 0px 0px 24px;
  border-color: #e2e8ee;
  border-width: 0;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
  /* Text */
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  text-transform: uppercase;
  color: #000000; }

.slider-bar-on {
  /* Right */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 86px;
  height: 32px;
  /* Fill/Primary/P Blue 600 */
  background: #307fe2;
  border-radius: 0px 24px 24px 0px;
  border-color: #307fe2;
  border-width: 0;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  z-index: 1;
  /* Text */
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff; }

.slider-bar-off {
  /* Right */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 86px;
  height: 32px;
  /* Fill/Primary/P Blue 600 */
  background: #e2e8ee;
  border-radius: 0px 24px 24px 0px;
  border-color: #e2e8ee;
  border-width: 0;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  z-index: 1;
  /* Text */
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  text-transform: uppercase;
  color: #000000; }

.reporting-partial {
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px; }

.reporting-partial::after {
  content: '';
  display: block;
  clear: both; }

.reporting-100w {
  padding: 20px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px; }

.other-report {
  padding: 30px;
  margin: 32px 0px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px; }

.singleDay-sales {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  color: #000000; }

.singleDay-date {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #69787f; }

.not-pro-banner {
  background: #e6f4e6;
  border-radius: 4px;
  width: 100%;
  font-family: 'Source Sans Pro';
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1); }

.not-pro {
  opacity: 0.5;
  filter: grayscale(100%); }

.control-menu {
  width: 90%;
  border-radius: 4px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #141e25; }
  .control-menu .menu-item {
    color: #141e25;
    border-radius: 4px; }
    .control-menu .menu-item.active {
      font-weight: 700;
      color: #307fe2;
      padding: 7px;
      border-bottom: 1px solid #307fe2;
      background: #e2e8ee; }
    .control-menu .menu-item .menu-link {
      color: #141e25;
      width: 100%; }
      .control-menu .menu-item .menu-link.active {
        color: #307fe2; }

.vh-65 {
  min-height: 65vh; }

.dark-gray-border {
  border-radius: 8px;
  border: 3px solid #e2e8ee; }

.control-label {
  padding: 8px 16px;
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border: 0;
  border-radius: 4px; }

.control-input {
  padding: 8px 16px;
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  border: 0;
  background: #e2e8ee;
  border-radius: 4px; }

.header-nav {
  width: 90%; }
  .header-nav .nav-item {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    align-items: center;
    border-bottom: 1px solid #e2e8ee;
    display: flex;
    flex: 1; }
    .header-nav .nav-item .nav-link {
      color: #141e25;
      width: 100%;
      padding: 7px;
      -webkit-appearance: unset !important; }
      .header-nav .nav-item .nav-link:hover {
        border-bottom: 1px solid #307fe2; }
      .header-nav .nav-item .nav-link.active {
        font-weight: 700;
        color: #307fe2;
        padding: 7px;
        border-bottom: 1px solid #307fe2; }

.pg-footer-text {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #808b90; }

.pg-footer {
  padding: 40px 0px;
  height: 104px; }

@media (max-width: 768px) {
  .mobile-client-graph {
    margin-left: 3.3%; } }
