.gallery {
  padding: 1.25rem 0; }
  .gallery .gallery-title {
    font-size: 0.875rem;
    font-weight: bold; }
  .gallery .gallery-item img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: contain; }
  .gallery .gallery-item .photo-name {
    text-align: center;
    font-size: 0.875rem;
    margin: 0;
    padding-top: 10px; }

.gallery-container-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  min-height: unset !important;
  display: block !important; }
  .gallery-container-modal .carouselContainer {
    height: calc((100vh)*.6);
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%; }
    @media screen and (max-width: 767px) {
      .gallery-container-modal .carouselContainer {
        height: calc((100vh)*.2); } }
  .gallery-container-modal .centerModal {
    width: 743px;
    margin: 1.75rem auto; }
    .gallery-container-modal .centerModal .gallery-modal {
      width: 743px;
      height: auto;
      padding: 3.5rem; }
      @media screen and (max-width: 743px) {
        .gallery-container-modal .centerModal .gallery-modal {
          width: 100%; } }
      .gallery-container-modal .centerModal .gallery-modal .carPicture {
        object-fit: contain;
        width: 100%;
        height: 100%; }
      .gallery-container-modal .centerModal .gallery-modal .close-icon {
        position: absolute;
        right: 1rem;
        top: 1rem;
        cursor: pointer; }
      .gallery-container-modal .centerModal .gallery-modal .photo-verifies {
        text-align: center;
        margin: 2rem 0; }
        .gallery-container-modal .centerModal .gallery-modal .photo-verifies .icon-check {
          margin-left: 1.5rem; }
      .gallery-container-modal .centerModal .gallery-modal .verifiesWieght {
        font-weight: 600; }
      .gallery-container-modal .centerModal .gallery-modal .photo-des {
        text-align: center; }
      .gallery-container-modal .centerModal .gallery-modal .download-all {
        text-transform: uppercase;
        color: #007bff;
        font-weight: bold;
        text-align: center;
        padding: 2rem 0; }
        .gallery-container-modal .centerModal .gallery-modal .download-all .icon-download {
          margin-left: 1rem;
          margin-bottom: .15rem; }
      .gallery-container-modal .centerModal .gallery-modal .carousel-indicators li {
        background-color: #A2A2A2;
        width: 8px;
        height: 8px;
        border-radius: 50%; }
        .gallery-container-modal .centerModal .gallery-modal .carousel-indicators li.active {
          background-color: black; }
