.invoice-detail-history {
  margin-top: 3rem; }
  .invoice-detail-history .title {
    font-size: 14px;
    color: #171E23;
    letter-spacing: 0;
    line-height: 22.4px;
    margin-bottom: 5px;
    font-weight: 600; }
  .invoice-detail-history .payment {
    background: #FFFFFF;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-bottom: 1rem; }
    .invoice-detail-history .payment .payment-info {
      padding: 1rem; }
      @media screen and (min-width: 768px) {
        .invoice-detail-history .payment .payment-info {
          flex-wrap: nowrap; } }
      .invoice-detail-history .payment .payment-info .topSection {
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px; }
        .invoice-detail-history .payment .payment-info .topSection .tip {
          font-size: 24px !important;
          font-weight: 300 !important; }
        .invoice-detail-history .payment .payment-info .topSection .boldInfo {
          font-size: 18px;
          color: #171E23;
          letter-spacing: 0;
          line-height: 22px;
          font-weight: 600; }
        .invoice-detail-history .payment .payment-info .topSection .lightInfo {
          font-size: 14px;
          color: #A2A2A2;
          letter-spacing: 0;
          line-height: 20px; }
      .invoice-detail-history .payment .payment-info .lineSplit {
        height: 2px;
        width: 100%;
        background-color: #F9F9F9; }
      .invoice-detail-history .payment .payment-info .bottomSection {
        padding-top: 1rem;
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px; }
      .invoice-detail-history .payment .payment-info .btn-view-receipt {
        color: #307FE2;
        text-align: center;
        padding-top: 1rem;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: bold; }
        .invoice-detail-history .payment .payment-info .btn-view-receipt img {
          margin-left: 1rem; }

.receipt-modal .centerModal {
  max-width: 41.4375rem !important;
  width: 100%; }
  @media screen and (min-width: 768px) {
    .receipt-modal .centerModal .modal-long-content {
      padding: 3.5rem 7.25rem !important; } }
  .receipt-modal .centerModal .modal-long-content .receipt-modal-header {
    padding-bottom: 1.5rem; }
    .receipt-modal .centerModal .modal-long-content .receipt-modal-header .amount {
      font-size: 2.625rem;
      height: 2.5rem;
      line-height: 2.5rem;
      color: #353535;
      margin-bottom: .125rem; }
    .receipt-modal .centerModal .modal-long-content .receipt-modal-header .amount-text {
      background-color: #353535;
      color: #F9FAFB;
      height: 24px;
      display: inline-block;
      border-radius: 23.5px;
      width: 9.5rem;
      text-align: center; }
  .receipt-modal .centerModal .modal-long-content .receipt-modal-content {
    padding: 1.5rem 0; }
    .receipt-modal .centerModal .modal-long-content .receipt-modal-content .info {
      display: flex; }
      .receipt-modal .centerModal .modal-long-content .receipt-modal-content .info .info-title {
        font-weight: bold; }
      .receipt-modal .centerModal .modal-long-content .receipt-modal-content .info .info-sub {
        flex-grow: 1;
        text-align: right; }
    .receipt-modal .centerModal .modal-long-content .receipt-modal-content .expand-evidence-text {
      text-align: center;
      font-size: 0.75rem;
      font-weight: 900;
      line-height: 16.4px;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: #307FE2; }
      .receipt-modal .centerModal .modal-long-content .receipt-modal-content .expand-evidence-text img {
        margin-left: 10px; }
    .receipt-modal .centerModal .modal-long-content .receipt-modal-content .expand-evidence {
      padding: 20px 10px;
      margin-top: 20px;
      position: relative; }
      .receipt-modal .centerModal .modal-long-content .receipt-modal-content .expand-evidence .rectangle-top-left {
        position: absolute;
        left: -10px;
        top: 0; }
      .receipt-modal .centerModal .modal-long-content .receipt-modal-content .expand-evidence .rectangle-top-right {
        position: absolute;
        right: -10px;
        top: 0;
        transform: rotate(90deg); }
      .receipt-modal .centerModal .modal-long-content .receipt-modal-content .expand-evidence .rectangle-bottom-left {
        position: absolute;
        left: -10px;
        bottom: 0;
        transform: rotate(-90deg); }
      .receipt-modal .centerModal .modal-long-content .receipt-modal-content .expand-evidence .rectangle-bottom-right {
        position: absolute;
        right: -10px;
        bottom: 0;
        transform: rotate(180deg); }
    .receipt-modal .centerModal .modal-long-content .receipt-modal-content .payment-note {
      margin: 1rem 0;
      font-size: 0.75rem;
      color: #171E23; }
  .receipt-modal .centerModal .modal-long-content .btn-delete-payment {
    color: #307FE2;
    text-align: center;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;
    margin: 0 auto; }
    .receipt-modal .centerModal .modal-long-content .btn-delete-payment img {
      margin-left: 10px; }
  .receipt-modal .centerModal .modal-long-content .receipt-modal-footer .btn-email-receipt {
    width: 100%;
    background-color: #307FE2;
    font-size: .875rem;
    font-style: normal;
    font-weight: 900;
    height: 3rem; }

.enlarge-evidence {
  display: none;
  justify-content: center;
  align-items: center;
  height: 100vh; }
  .enlarge-evidence img {
    width: 50%;
    object-fit: contain; }
