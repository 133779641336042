.module-list {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 0.4rem; }
  @media screen and (min-width: 600px) {
    .module-list {
      grid-template-columns: auto auto auto auto;
      gap: 1rem; } }
  @media screen and (min-width: 768px) {
    .module-list {
      grid-template-columns: auto auto auto auto auto; } }

.progressbar {
  counter-reset: step;
  padding: 0; }

.progressbar li {
  float: left;
  list-style: none;
  position: relative;
  text-align: center;
  width: calc(100% / 5); }

.progressbar li:before {
  background: #E3F1FD;
  border: 2px solid #307FE2;
  border-radius: 50%;
  color: #bebebe;
  content: counter(step);
  counter-increment: step;
  display: block;
  font-weight: 700;
  height: 30px;
  line-height: 27px;
  margin: 0 auto 10px;
  text-align: center;
  width: 30px; }

.progressbar li:after {
  background: rgba(0, 0, 0, 0.1);
  content: '';
  height: 1px;
  left: -50%;
  position: absolute;
  top: 15px;
  width: 100%;
  z-index: -1; }

.progressbar li:first-child:after {
  content: none; }

.progressbar li.active:after,
.progressbar li.complete:after {
  background: #E3F1FD; }

.progressbar li.active:before,
.progressbar li.complete:before {
  background: #307FE2;
  border-color: #307FE2;
  color: #fff; }

.progressbar li.active {
  color: #307FE2;
  font-weight: 700; }
