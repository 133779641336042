.vehicles {
  margin-top: 3rem; }
  .vehicles .title {
    font-size: 14px;
    color: #171E23;
    letter-spacing: 0;
    line-height: 22.4px;
    margin-bottom: 5px;
    font-weight: 600; }
  .vehicles .vehicle {
    background-color: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    border-radius: 4px; }
    .vehicles .vehicle .vehicle-info {
      display: flex;
      position: relative;
      padding: 1rem;
      flex-wrap: wrap; }
      @media screen and (min-width: 768px) {
        .vehicles .vehicle .vehicle-info {
          flex-wrap: nowrap; } }
      .vehicles .vehicle .vehicle-info .vehicle-number {
        position: absolute;
        top: 1rem;
        left: 1rem;
        font-size: 1rem;
        height: 20px;
        font-weight: bold; }
      .vehicles .vehicle .vehicle-info .vehicle-img {
        width: 100%;
        max-height: 100px;
        margin-right: 1rem;
        object-fit: contain; }
        @media screen and (min-width: 575px) {
          .vehicles .vehicle .vehicle-info .vehicle-img {
            width: 200px; } }
      .vehicles .vehicle .vehicle-info .vehicle-des {
        flex-grow: 1;
        align-self: center;
        position: relative; }
        .vehicles .vehicle .vehicle-info .vehicle-des .vehicle-name {
          font-size: 21px; }
        .vehicles .vehicle .vehicle-info .vehicle-des .vehicle-material {
          font-size: 0.874rem; }
      .vehicles .vehicle .vehicle-info .vehicle-price {
        width: 7rem;
        font-weight: bold;
        text-align: right;
        position: absolute;
        top: 0;
        right: 1rem; }
      .vehicles .vehicle .vehicle-info .icon-toggle {
        align-self: flex-end; }
        .vehicles .vehicle .vehicle-info .icon-toggle .fa-icon-toggle {
          color: #007bff;
          height: 24px;
          width: 24px;
          display: flex;
          align-content: center;
          justify-content: center; }
    .vehicles .vehicle .vehicle-gallery {
      padding: 0rem 2.875rem; }
    .vehicles .vehicle .download-all {
      text-transform: uppercase;
      color: #007bff;
      font-weight: bold;
      text-align: center;
      padding: 2rem 0; }
      .vehicles .vehicle .download-all .icon-download {
        margin-left: 1rem;
        margin-bottom: .15rem; }
    .vehicles .vehicle #vehicle-gallery .carousel-indicators, .vehicles .vehicle #vehicle-gallery .carousel-control-next, .vehicles .vehicle #vehicle-gallery .carousel-control-prev {
      display: none; }
    .vehicles .vehicle .services {
      display: flex;
      flex-flow: row wrap; }
      .vehicles .vehicle .services .services-title {
        height: 1.875rem;
        font-size: 0.875rem;
        font-weight: bold; }
      .vehicles .vehicle .services .service-items {
        width: 100%;
        margin-right: 0;
        margin-left: 0; }
        .vehicles .vehicle .services .service-items .service.left {
          padding-left: 0; }
        .vehicles .vehicle .services .service-items .service.right {
          padding-right: 0; }
        .vehicles .vehicle .services .service-items .service .service-content {
          padding: 0.625rem;
          background-color: #ececec;
          display: flex; }
          .vehicles .vehicle .services .service-items .service .service-content .service-name {
            font-size: 0.875rem;
            font-weight: bold; }
          .vehicles .vehicle .services .service-items .service .service-content .service-sub {
            flex-grow: 1;
            text-align: right;
            font-size: 0.75rem; }
