.layout-default main {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important; }

.payment-detail-page {
  color: #353535; }
  .payment-detail-page .bread-crumbs {
    margin-bottom: 50px; }
    .payment-detail-page .bread-crumbs h4 {
      font-size: 0.875rem;
      font-weight: bold;
      margin: 0; }
      .payment-detail-page .bread-crumbs h4 .date-created {
        font-weight: 500; }
  .payment-detail-page .payment-complete {
    position: fixed;
    top: 70px;
    bottom: 0;
    left: 1rem;
    right: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .payment-detail-page .payment-complete .notice-message {
      font-size: 1.5rem;
      font-weight: bold; }
    .payment-detail-page .payment-complete .group-bottom {
      position: absolute;
      bottom: 5.5rem; }
      .payment-detail-page .payment-complete .group-bottom .btn-complete {
        height: 3rem;
        width: 22.75rem;
        text-transform: uppercase;
        font-weight: bold; }
      .payment-detail-page .payment-complete .group-bottom .btn-back {
        padding: 1rem;
        width: 100%;
        text-align: center;
        display: inline-block;
        color: #0b65e0;
        text-transform: uppercase;
        font-weight: bold;
        cursor: pointer; }
        .payment-detail-page .payment-complete .group-bottom .btn-back i {
          margin-left: 1rem; }
  .payment-detail-page .payment {
    display: flex;
    justify-content: center;
    margin-bottom: 10px; }
    .payment-detail-page .payment .payment-info {
      width: 33.33%; }
      .payment-detail-page .payment .payment-info .summary .summary-item h1 {
        padding-left: 0px; }
      .payment-detail-page .payment .payment-info .summary .summary-item .total {
        border-radius: 23.5px;
        background-color: #353535;
        font-size: 16px;
        color: #f9fafb;
        line-height: 32px;
        text-align: center;
        padding: 0 32px; }
      .payment-detail-page .payment .payment-info .summary .summary-primary {
        display: flex;
        justify-content: center;
        margin-bottom: 10px; }
        @media only screen and (min-width: 576px) {
          .payment-detail-page .payment .payment-info .summary .summary-primary {
            display: block;
            padding-right: 2.125rem;
            text-align: right;
            margin-bottom: 0px; } }
        @media only screen and (min-width: 1200px) {
          .payment-detail-page .payment .payment-info .summary .summary-primary {
            text-align: left;
            margin-bottom: 60px; } }
        .payment-detail-page .payment .payment-info .summary .summary-primary .paymentToEnter {
          border: none; }
        .payment-detail-page .payment .payment-info .summary .summary-primary .paymentTitle {
          color: #171e23;
          font-weight: 600;
          margin-bottom: unset;
          line-height: 32px;
          padding-left: 16px;
          text-align: center; }
          @media only screen and (min-width: 576px) {
            .payment-detail-page .payment .payment-info .summary .summary-primary .paymentTitle {
              padding-left: 16px;
              text-align: left; } }
        .payment-detail-page .payment .payment-info .summary .summary-primary .paymentInputContainer {
          font-size: 2.5rem;
          display: flex;
          border: 0.0625rem solid transparent;
          border-radius: 0.25rem;
          background: rgba(105, 105, 105, 0.12); }
          .payment-detail-page .payment .payment-info .summary .summary-primary .paymentInputContainer .form-group {
            margin-bottom: 0px; }
          .payment-detail-page .payment .payment-info .summary .summary-primary .paymentInputContainer input {
            background-color: transparent;
            border: transparent; }
          .payment-detail-page .payment .payment-info .summary .summary-primary .paymentInputContainer input:focus,
          .payment-detail-page .payment .payment-info .summary .summary-primary .paymentInputContainer select:focus,
          .payment-detail-page .payment .payment-info .summary .summary-primary .paymentInputContainer textarea:focus,
          .payment-detail-page .payment .payment-info .summary .summary-primary .paymentInputContainer button:focus {
            outline: none !important; }
        .payment-detail-page .payment .payment-info .summary .summary-primary .paymentContainer:focus {
          outline: none !important; }
      .payment-detail-page .payment .payment-info .summary .summary-contact {
        align-self: center;
        padding-left: 2.125rem;
        padding-right: 2.125rem;
        flex-direction: column;
        border-left: none !important;
        border-left: none;
        margin-bottom: 2rem; }
        @media only screen and (min-width: 576px) {
          .payment-detail-page .payment .payment-info .summary .summary-contact {
            border-left: 1px solid #dee2e6 !important;
            margin-bottom: 5rem; } }
        .payment-detail-page .payment .payment-info .summary .summary-contact > div {
          text-align: center; }
          @media only screen and (min-width: 576px) {
            .payment-detail-page .payment .payment-info .summary .summary-contact > div {
              text-align: left; } }
        .payment-detail-page .payment .payment-info .summary .summary-contact .icon {
          margin-right: 5px; }
        .payment-detail-page .payment .payment-info .summary .summary-contact .text-sub {
          font-size: 0.75rem; }
    .payment-detail-page .payment .payment-method {
      width: 66.67%; }
      .payment-detail-page .payment .payment-method .amount-balance {
        margin: 0 -4px; }
        .payment-detail-page .payment .payment-method .amount-balance .amount {
          margin: 0 4px; }
      .payment-detail-page .payment .payment-method .form {
        width: 100%; }
        .payment-detail-page .payment .payment-method .form .method-detail {
          display: flex; }
          .payment-detail-page .payment .payment-method .form .method-detail .form-input-label .form-label {
            text-transform: unset;
            font-size: 1rem;
            font-weight: 600; }
          .payment-detail-page .payment .payment-method .form .method-detail .detail-method .method-options {
            display: flex;
            margin: 0 -4px;
            align-items: center;
            margin-bottom: 16px; }
            .payment-detail-page .payment .payment-method .form .method-detail .detail-method .method-options > div {
              color: #171e23;
              width: 50%;
              margin: 0 4px;
              height: 48px;
              border-radius: 4px;
              border: solid 0.9px #a2a2a2;
              padding: 8px 10px;
              font-size: 1rem;
              line-height: 42px;
              font-weight: 600;
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;
              letter-spacing: 0; }
              @media only screen and (min-width: 576px) {
                .payment-detail-page .payment .payment-method .form .method-detail .detail-method .method-options > div {
                  font-size: .9rem; } }
              .payment-detail-page .payment .payment-method .form .method-detail .detail-method .method-options > div.active {
                background-color: #0b65e0;
                border: solid 0.9px #0b65e0;
                color: #f6f7f8; }
            .payment-detail-page .payment .payment-method .form .method-detail .detail-method .method-options .oval {
              width: 16px;
              height: 16px;
              border: solid 2px #a2a2a2;
              border-radius: 50%; }
          .payment-detail-page .payment .payment-method .form .method-detail .detail-method .payment-input .country hr {
            border-top: 1px solid rgba(0, 0, 0, 0.05);
            margin: 0; }
          .payment-detail-page .payment .payment-method .form .method-detail .detail-method .payment-input .country .form-input-label:first-child .form-group {
            margin-bottom: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; }
          .payment-detail-page .payment .payment-method .form .method-detail .detail-method .payment-input .country .form-input-label:last-child .form-group {
            border-top-left-radius: 0;
            border-top-right-radius: 0; }
          .payment-detail-page .payment .payment-method .form .method-detail .detail-method .payment-input .evidence {
            border-radius: 4px;
            background-color: #ececec;
            padding: 81px 45px; }
            .payment-detail-page .payment .payment-method .form .method-detail .detail-method .payment-input .evidence .description {
              font-size: 14px;
              font-weight: 600;
              color: #171e23;
              line-height: 32px; }
            .payment-detail-page .payment .payment-method .form .method-detail .detail-method .payment-input .evidence .add {
              color: #307FE2;
              font-size: 12px;
              font-weight: 900;
              line-height: 32px;
              cursor: pointer; }
          .payment-detail-page .payment .payment-method .form .method-detail .detail-method .payment-input .evidence-preview {
            padding: 50px 0;
            border-radius: 4px;
            position: relative;
            background-color: #ececec;
            min-height: 224px;
            text-align: center;
            display: flex;
            flex-direction: column; }
            .payment-detail-page .payment .payment-method .form .method-detail .detail-method .payment-input .evidence-preview .preview {
              padding: 0 40px; }
              .payment-detail-page .payment .payment-method .form .method-detail .detail-method .payment-input .evidence-preview .preview img {
                max-width: 100%;
                max-height: 100%; }
            .payment-detail-page .payment .payment-method .form .method-detail .detail-method .payment-input .evidence-preview img.del-preview {
              width: 1.25rem;
              align-self: flex-end;
              position: absolute;
              cursor: pointer;
              top: 1.25rem;
              right: 1.25rem; }
          .payment-detail-page .payment .payment-method .form .method-detail .detail-method .tip {
            display: flex;
            margin: 16px -4px; }
            .payment-detail-page .payment .payment-method .form .method-detail .detail-method .tip .tip-block {
              width: 33.33%; }
              .payment-detail-page .payment .payment-method .form .method-detail .detail-method .tip .tip-block .custom {
                margin: 34px 4px 4px;
                font-size: 12px;
                font-weight: 600; }
              .payment-detail-page .payment .payment-method .form .method-detail .detail-method .tip .tip-block .custom-value {
                margin: 4px; }
                .payment-detail-page .payment .payment-method .form .method-detail .detail-method .tip .tip-block .custom-value > div {
                  height: 40px;
                  display: flex;
                  align-items: center; }
              .payment-detail-page .payment .payment-method .form .method-detail .detail-method .tip .tip-block .tip-percent {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 16px !important;
                font-size: 14px;
                font-weight: bold;
                line-height: 12px;
                border-radius: 4px;
                border: solid 0.9px #a2a2a2;
                height: 40px;
                margin: 8px 4px; }
                .payment-detail-page .payment .payment-method .form .method-detail .detail-method .tip .tip-block .tip-percent .amount {
                  font-size: 10px;
                  font-weight: normal;
                  color: #696969; }
          .payment-detail-page .payment .payment-method .form .method-detail .extra-info .btn-pay-invoice {
            padding: 20px 0px; }
            .payment-detail-page .payment .payment-method .form .method-detail .extra-info .btn-pay-invoice .btn {
              width: 100%;
              text-transform: uppercase;
              height: 48px;
              font-size: 12px;
              font-weight: 900;
              letter-spacing: 1px; }
          .payment-detail-page .payment .payment-method .form .method-detail .extra-info textarea {
            font-size: 16px;
            line-height: 1.25;
            padding-bottom: 9px; }
  .payment-detail-page .checkout-stripe {
    margin-bottom: 8px; }
    .payment-detail-page .checkout-stripe .label {
      color: #171e23;
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: unset;
      line-height: 32px;
      padding-left: 16px; }
    .payment-detail-page .checkout-stripe .form-control {
      background-color: #ECECEC !important;
      border: none !important;
      height: 48px;
      font-size: 14px;
      color: #171E23;
      border-radius: 4px; }
      .payment-detail-page .checkout-stripe .form-control:focus {
        box-shadow: none !important; }
      .payment-detail-page .checkout-stripe .form-control::placeholder {
        color: #696969; }
    .payment-detail-page .checkout-stripe .card-number {
      width: 100%;
      padding: 0 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      height: 40px;
      display: flex;
      align-items: center; }
    .payment-detail-page .checkout-stripe .bg-grey {
      background-color: #F2F2F2;
      border-radius: 4px; }
      .payment-detail-page .checkout-stripe .bg-grey .form-group {
        margin: 0; }
      .payment-detail-page .checkout-stripe .bg-grey .split-form + .split-form {
        border-top: 1px solid #E5E5E5; }
    .payment-detail-page .checkout-stripe .form-group {
      margin: 0; }
    .payment-detail-page .checkout-stripe .decor-cards {
      background: url(/75ba0db5c8555efbd178122a066fa0d5.svg) no-repeat calc(100% - 16px) center;
      background-size: auto 15px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      padding: 15px !important; }
    .payment-detail-page .checkout-stripe .decor-cvc {
      background: url(/93e60ecf5d1291461989a635985aac9a.svg) no-repeat calc(100% - 14px) center;
      background-size: auto 18px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 0px;
      padding: 15px !important; }
    .payment-detail-page .checkout-stripe .decor-expiry {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      padding: 15px !important;
      border-right: 1px solid #e0e0df !important; }
    .payment-detail-page .checkout-stripe .lineSeperator {
      height: 1px;
      background-color: #e0e0df; }
    .payment-detail-page .checkout-stripe .card-expiry {
      width: 50%;
      padding: 0 20px;
      border-right: 1px solid rgba(0, 0, 0, 0.05);
      height: 40px;
      display: flex;
      align-items: center; }
    .payment-detail-page .checkout-stripe .card-cvc {
      width: 50%;
      padding: 0 20px;
      height: 40px;
      display: flex;
      align-items: center; }
    .payment-detail-page .checkout-stripe .card-error {
      border: solid 0.9058px rgba(232, 6, 4, 0.5);
      background: #ececec;
      box-shadow: 0px 0px 8px rgba(232, 6, 4, 0.5); }
    .payment-detail-page .checkout-stripe .field {
      height: 40px;
      display: flex;
      align-items: center;
      background-color: #ECECEC;
      border: 0.0625rem solid transparent;
      border-radius: 0.25rem; }
      .payment-detail-page .checkout-stripe .field:first-child {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0; }
      .payment-detail-page .checkout-stripe .field:last-child {
        border-top-right-radius: 0;
        border-top-left-radius: 0; }
    .payment-detail-page .checkout-stripe .input {
      display: block;
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      color: #000000;
      background-color: transparent;
      border: unset; }
  .payment-detail-page .input-file {
    display: none; }

.cards-error-msg {
  border: 1px solid #E80604;
  border-radius: 3px;
  padding: 1rem;
  display: flex; }
  .cards-error-msg .warnning-icon {
    width: 2.5rem;
    display: flex;
    align-items: baseline; }
  .cards-error-msg .error-msg p {
    color: #E80604;
    font-size: 12px; }
  .cards-error-msg .error-msg span {
    vertical-align: sub; }
