.table-emails.table {
  border-collapse: separate;
  border-spacing: 0 .5rem; }
  .table-emails.table thead tr {
    font-size: .875rem;
    color: #979797; }
  .table-emails.table tbody tr {
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.05);
    background-color: #fff; }
    .table-emails.table tbody tr td {
      padding: 1rem; }

.label-status {
  border-radius: 23.5px;
  color: #fff;
  width: 8.75rem;
  padding: 0.25rem;
  text-align: center; }

.emails-mobile {
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.05); }
  .emails-mobile .email-item {
    background-color: #fff;
    font-size: 0.75rem; }
