.wrapper .signature {
  height: 35vh; }
  .wrapper .signature .btn-clear {
    position: absolute;
    top: 0.5rem;
    right: 1rem; }
  .wrapper .signature .input-body {
    width: 50%; }
    @media screen and (max-width: 420px) {
      .wrapper .signature .input-body {
        width: 75%; } }
  .wrapper .signature .input-wrapper {
    position: absolute;
    width: 50% !important;
    top: 50%; }
    @media screen and (max-width: 420px) {
      .wrapper .signature .input-wrapper {
        width: 75% !important; } }
  .wrapper .signature .signature-input {
    text-transform: capitalize;
    font-family: 'La Belle Aurore', cursive;
    font-size: 36px;
    padding: 0.5rem 1.5rem;
    background: transparent;
    border: none;
    height: 70px;
    width: 90%; }
    @media screen and (max-width: 1204px) {
      .wrapper .signature .signature-input {
        font-size: 24px;
        height: 50px; } }
    @media screen and (max-width: 420px) {
      .wrapper .signature .signature-input {
        font-size: 16px;
        height: 40px; } }

.wrapper .btn-dissable {
  background-color: #EAEAEA !important;
  color: unset !important;
  border: none !important; }
