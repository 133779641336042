.client-text-alerts .add-other-alert-modal {
  display: flex !important; }
  .client-text-alerts .add-other-alert-modal .alert-form {
    background-color: transparent; }
    .client-text-alerts .add-other-alert-modal .alert-form .alert-form__message-input {
      flex-basis: calc(100% - 1rem); }
  .client-text-alerts .add-other-alert-modal #centerModal {
    max-width: 600px;
    flex-grow: 1; }
    .client-text-alerts .add-other-alert-modal #centerModal .modal-long-content {
      padding: 24px; }
    .client-text-alerts .add-other-alert-modal #centerModal .closeIcon {
      right: 20px;
      top: 20px; }

.client-text-alerts .add-btn {
  display: flex;
  justify-content: flex-end;
  font-size: 0.75rem;
  font-weight: 700;
  color: #307FE2;
  padding: 0 2rem !important;
  margin-top: 1rem; }
