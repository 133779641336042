.payment-content {
  padding: 2rem 0 0; }
  .payment-content .nav-tabs {
    display: flex !important; }
    .payment-content .nav-tabs .nav-item {
      min-width: 50%; }
      @media screen and (min-width: 768px) {
        .payment-content .nav-tabs .nav-item {
          min-width: 11.5rem; } }
      .payment-content .nav-tabs .nav-item .nav-link {
        border-color: #dee2e6 #dee2e6;
        height: 48px;
        line-height: 22.4px;
        display: flex;
        justify-content: center;
        align-items: center; }
        .payment-content .nav-tabs .nav-item .nav-link img {
          margin-left: 1rem; }
        .payment-content .nav-tabs .nav-item .nav-link:first-child {
          border-top-right-radius: 0; }
        .payment-content .nav-tabs .nav-item .nav-link:last-child {
          border-top-left-radius: 0; }
        .payment-content .nav-tabs .nav-item .nav-link.active {
          border-color: #dee2e6 #dee2e6 #fff !important; }
  .payment-content .tabs-content {
    padding: 2rem;
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem; }
    .payment-content .tabs-content .pay-with-card .form-group label {
      font-size: .875rem;
      color: #2a2a2a;
      font-weight: 600;
      line-height: 22.4px;
      padding-left: 0.75rem; }
    .payment-content .tabs-content .pay-with-card .form-group .form-control {
      background: #ececec;
      border: none;
      box-shadow: none; }
    .payment-content .tabs-content .pay-with-card .form-group .decor-cards {
      display: flex;
      background: #ececec; }
      .payment-content .tabs-content .pay-with-card .form-group .decor-cards .StripeElement {
        flex-grow: 1; }
      .payment-content .tabs-content .pay-with-card .form-group .decor-cards .icon-visa {
        width: 24px;
        align-self: center;
        margin-right: 1rem; }
    .payment-content .tabs-content .pay-with-card .form-group .cvv-number {
      width: 10rem;
      background: #ececec;
      display: flex; }
      .payment-content .tabs-content .pay-with-card .form-group .cvv-number .icon-brand {
        padding-left: 1rem;
        padding-right: 1rem; }
    .payment-content .tabs-content .pay-with-card .address1 {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem; }
    .payment-content .tabs-content .pay-with-card .address2 {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      margin-left: 0; }
  .payment-content .pay-notice {
    color: #000000;
    padding: 1.25rem;
    text-align: center; }
    .payment-content .pay-notice img {
      margin-right: .625rem; }
  .payment-content .btn-pay-with-card {
    width: 21.5rem;
    height: 3.5rem;
    font-size: 0.75rem;
    font-weight: 900;
    line-height: 22.4px; }
  .payment-content .btn-connect-bank {
    width: 100% !important;
    max-width: 270px;
    height: 3.5rem;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 900;
    line-height: 22.4px; }
  .payment-content .btn-save-bank {
    margin-top: 4rem;
    width: 13.75rem;
    text-transform: uppercase;
    height: 3rem;
    font-size: 0.75rem;
    font-weight: 900; }
