.credit-card-processing-page .btn-custom {
  max-width: 219px;
  height: 53px;
  background-color: #0B65E0;
  font-size: 12px;
  font-weight: bold;
  height: 53px;
  width: 219px;
  color: #ffffff;
  margin-top: 8px;
  margin-bottom: 3px; }
  @media screen and (min-width: 576px) {
    .credit-card-processing-page .btn-custom {
      margin: 0px; } }

.credit-card-processing-page .btn-view-stripe {
  text-transform: uppercase; }

.credit-card-processing-page .credit-card-panel .title {
  color: #171E23;
  font-size: 12px;
  font-weight: bold;
  margin-top: 0.5rem; }

.credit-card-processing-page .credit-card-panel img {
  margin-top: 30px; }

.credit-card-processing-page .credit-card-panel .info-connect-stripe {
  font-size: 7px;
  line-height: 9px; }
  @media screen and (min-width: 600px) {
    .credit-card-processing-page .credit-card-panel .info-connect-stripe {
      font-size: 10px;
      line-height: 14px; } }
  @media screen and (min-width: 768px) {
    .credit-card-processing-page .credit-card-panel .info-connect-stripe {
      font-size: 14px;
      line-height: 18px; } }
  @media screen and (min-width: 992px) {
    .credit-card-processing-page .credit-card-panel .info-connect-stripe {
      font-size: 16px;
      line-height: 24px; } }
  @media screen and (min-width: 1200px) {
    .credit-card-processing-page .credit-card-panel .info-connect-stripe {
      font-size: 14px;
      line-height: 18px; } }
  @media screen and (min-width: 1400px) {
    .credit-card-processing-page .credit-card-panel .info-connect-stripe {
      font-size: 16px;
      line-height: 24px; } }

.credit-card-processing-page .credit-card-panel .form-connect-stripe {
  position: absolute;
  top: 40%;
  left: 9%;
  right: 32%;
  bottom: 10%;
  display: flex;
  justify-content: space-between;
  flex-direction: column; }
  .credit-card-processing-page .credit-card-panel .form-connect-stripe p {
    color: white; }

.credit-card-processing-page .credit-card-panel .btn-connect-stripe {
  color: #171E23;
  font-weight: bold;
  background: #fff;
  border-color: #979797;
  font-size: 0.5rem;
  height: 25%;
  width: 45%; }
  @media screen and (min-width: 768px) {
    .credit-card-processing-page .credit-card-panel .btn-connect-stripe {
      font-size: 0.8rem; } }
  @media screen and (min-width: 992px) {
    .credit-card-processing-page .credit-card-panel .btn-connect-stripe {
      font-size: 1rem; } }

.credit-card-processing-page .credit-card-panel .note-connect-stripe {
  width: 45%;
  text-align: center; }
  .credit-card-processing-page .credit-card-panel .note-connect-stripe .note-connect-stripe-label {
    font-weight: 700; }
  .credit-card-processing-page .credit-card-panel .note-connect-stripe p {
    font-size: 6px;
    line-height: 7px; }
  .credit-card-processing-page .credit-card-panel .note-connect-stripe .note-connect-stripe-label {
    font-size: 8px;
    margin-bottom: 0; }
  @media screen and (min-width: 600px) {
    .credit-card-processing-page .credit-card-panel .note-connect-stripe p {
      font-size: 8px;
      line-height: 9px; }
    .credit-card-processing-page .credit-card-panel .note-connect-stripe .note-connect-stripe-label {
      font-size: 10px;
      margin-bottom: 6px; } }
  @media screen and (min-width: 768px) {
    .credit-card-processing-page .credit-card-panel .note-connect-stripe p {
      font-size: 10px;
      line-height: 12px; }
    .credit-card-processing-page .credit-card-panel .note-connect-stripe .note-connect-stripe-label {
      font-size: 12px;
      margin-bottom: 8px; } }
  @media screen and (min-width: 992px) {
    .credit-card-processing-page .credit-card-panel .note-connect-stripe p {
      font-size: 12px;
      line-height: 14px; }
    .credit-card-processing-page .credit-card-panel .note-connect-stripe .note-connect-stripe-label {
      font-size: 14px;
      margin-bottom: 10px; } }

.credit-card-processing-page .credit-card--dropdown {
  margin-top: 30px; }

.credit-card-processing-page .credit-card-content .title {
  color: #171E23;
  font-size: 12px;
  font-weight: bold;
  margin-top: 0.5rem; }

.credit-card-processing-page .credit-card-content .sub {
  color: #000000;
  font-size: 12px;
  opacity: 0.5;
  line-height: 19px;
  margin-top: 1rem;
  margin-bottom: 2.5rem; }

.credit-card-processing-page .credit-card-content .order-card {
  margin-top: 1.5rem;
  margin-bottom: .5rem;
  display: flex;
  flex-direction: column;
  padding: 5.5%; }
  @media screen and (min-width: 576px) {
    .credit-card-processing-page .credit-card-content .order-card {
      flex-direction: row;
      margin-bottom: 2.5rem; } }
  .credit-card-processing-page .credit-card-content .order-card .order-card-module {
    flex-grow: 1; }
  .credit-card-processing-page .credit-card-content .order-card .order-card-reader {
    width: 100%;
    text-align: center;
    align-self: center;
    padding-left: 5.5%; }
    @media screen and (min-width: 576px) {
      .credit-card-processing-page .credit-card-content .order-card .order-card-reader {
        width: 35%; } }
    .credit-card-processing-page .credit-card-content .order-card .order-card-reader .btn-order-card {
      background-color: #0B65E0;
      font-size: 12px;
      font-weight: bold;
      height: 53px;
      max-width: 219px;
      width: 100%; }
    .credit-card-processing-page .credit-card-content .order-card .order-card-reader .card-reader-text {
      opacity: .5;
      font-size: 12px;
      font-weight: 400;
      line-height: 19px;
      margin-top: .5rem;
      color: #000000; }

.credit-card-processing-page .credit-card-content .pay-info {
  padding: 0 2.875rem 2rem;
  display: flex;
  flex-direction: column; }
  @media screen and (min-width: 576px) {
    .credit-card-processing-page .credit-card-content .pay-info {
      flex-direction: row; } }
  .credit-card-processing-page .credit-card-content .pay-info .pay-title {
    color: #353535;
    font-weight: bold; }
  .credit-card-processing-page .credit-card-content .pay-info .pay-price {
    font-size: 32px;
    color: #353535; }
  .credit-card-processing-page .credit-card-content .pay-info .pay-des {
    color: #353535; }
  .credit-card-processing-page .credit-card-content .pay-info .balance {
    text-align: center;
    width: 100%; }
    @media screen and (min-width: 576px) {
      .credit-card-processing-page .credit-card-content .pay-info .balance {
        width: 170px; } }
  .credit-card-processing-page .credit-card-content .pay-info .payout {
    text-align: center;
    flex-grow: 1; }
    .credit-card-processing-page .credit-card-content .pay-info .payout .payout-text {
      color: #0B65E0;
      font-weight: bold;
      text-decoration: underline;
      text-transform: uppercase;
      font-size: 12px;
      padding-top: 10px; }
  .credit-card-processing-page .credit-card-content .pay-info .earned {
    text-align: center;
    width: 100%; }
    @media screen and (min-width: 576px) {
      .credit-card-processing-page .credit-card-content .pay-info .earned {
        width: 170px; } }

.credit-card-processing-page .credit-card-content .tbl-payment .client {
  align-items: center;
  margin-bottom: 1rem; }
  @media screen and (min-width: 576px) {
    .credit-card-processing-page .credit-card-content .tbl-payment .client {
      margin-bottom: 0rem; } }

.credit-card-processing-page .credit-card-content .tbl-payment .amount {
  font-size: 1.125rem;
  color: #000000;
  display: block;
  text-align: center; }

.credit-card-processing-page .credit-card-content .tbl-payment .refundedDate {
  font-size: 0.75rem;
  font-weight: 300;
  display: block;
  text-align: center;
  color: #171E23; }

.credit-card-processing-page .credit-card-content .tbl-payment .payment-info {
  background-color: #f2f2f2;
  padding: 1rem; }
  .credit-card-processing-page .credit-card-content .tbl-payment .payment-info .payment-sub .date, .credit-card-processing-page .credit-card-content .tbl-payment .payment-info .payment-sub .paid, .credit-card-processing-page .credit-card-content .tbl-payment .payment-info .payment-sub .refund-amount {
    width: 33.33%; }
  .credit-card-processing-page .credit-card-content .tbl-payment .payment-info .payment-sub .refund-amount .amount, .credit-card-processing-page .credit-card-content .tbl-payment .payment-info .payment-sub .refund-amount .refundedDate {
    text-align: left !important; }

.credit-card-processing-page .credit-card-content .btn-refund {
  width: 90px !important;
  height: 30px !important;
  background-color: #696969 !important;
  font-weight: 700;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.02px; }
