.package-detail {
  width: 100%; }
  .package-detail .title {
    color: #2a2a2a;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 41px;
    text-transform: uppercase;
    letter-spacing: 0.26px; }
  .package-detail .pck-toggle {
    margin: 0px; }
    @media screen and (min-width: 576px) {
      .package-detail .pck-toggle {
        margin: 1.5rem 4rem; } }
    .package-detail .pck-toggle .form-toggle {
      padding: 0 !important;
      background-color: #fff; }
      .package-detail .pck-toggle .form-toggle .switch {
        width: 40px !important;
        height: 1.5rem !important; }
        .package-detail .pck-toggle .form-toggle .switch input:checked + .slider:before {
          transform: translateX(18px); }
        .package-detail .pck-toggle .form-toggle .switch .slider::before {
          height: 1rem;
          width: 1rem; }
      .package-detail .pck-toggle .form-toggle label {
        text-transform: initial; }
        .package-detail .pck-toggle .form-toggle label.label-before {
          margin-right: .75rem; }
        .package-detail .pck-toggle .form-toggle label.label-after {
          margin-left: .75rem; }
  .package-detail .transparant {
    background-color: transparent !important; }
  .package-detail .pck-content .pck-column {
    padding: 1rem;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 1px solid rgba(0, 0, 0, 0.1); }
    .package-detail .pck-content .pck-column .pck-info {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    .package-detail .pck-content .pck-column.active {
      background: rgba(35, 187, 255, 0.1); }
      .package-detail .pck-content .pck-column.active .btn-selected {
        background-color: #307FE2; }
      .package-detail .pck-content .pck-column.active .pck-des {
        font-weight: 600; }
    .package-detail .pck-content .pck-column .pck-info .pck-name {
      padding: 0.875rem 0;
      color: #2a2a2a;
      text-transform: uppercase;
      font-size: 1.25rem;
      font-weight: bold; }
    .package-detail .pck-content .pck-column .pck-info .pck-des {
      min-height: 185px;
      color: #2a2a2a;
      font-size: .875rem; }
    .package-detail .pck-content .pck-column .pck-info .pck-price {
      padding: 0.875rem 0;
      font-size: .875rem; }
    .package-detail .pck-content .pck-column .pck-select .pck-price-year {
      padding: 0.875rem 0;
      font-weight: bold;
      color: #2A2A2A;
      font-size: 1.5rem; }
    .package-detail .pck-content .pck-column .pck-select .dependento {
      font-size: .875rem;
      color: #929292;
      font-style: italic;
      line-height: 96%; }
    .package-detail .pck-content .pck-column .btnDisabled {
      background-color: black !important;
      cursor: default !important;
      color: #ffffff; }
    .package-detail .pck-content .pck-column .btn-selected {
      width: 100%;
      max-width: 9.75rem;
      background-color: #307FE2;
      margin: 1.5625rem 0;
      font-size: 1.125rem; }
      .package-detail .pck-content .pck-column .btn-selected.button[disabled] {
        background-color: black !important;
        cursor: default !important; }
    .package-detail .pck-content .pck-column .description-module {
      font-size: 0.75rem;
      line-height: 1rem; }
    .package-detail .pck-content .pck-column .pck-list .pck-items {
      margin: 0;
      padding: 0;
      list-style: none; }
      .package-detail .pck-content .pck-column .pck-list .pck-items .pck-item .pck-icon {
        margin-right: 0.5rem; }
      .package-detail .pck-content .pck-column .pck-list .pck-items .pck-item a.pck-item-content {
        display: block; }
        .package-detail .pck-content .pck-column .pck-list .pck-items .pck-item a.pck-item-content span {
          color: #2a2a2a;
          font-size: 0.75rem; }
  .package-detail .pck-content .tooltip-item {
    display: none;
    position: absolute;
    bottom: calc(100% + 8px);
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 5px;
    z-index: 1;
    color: white;
    padding: 8px 10px; }
    .package-detail .pck-content .tooltip-item:before {
      content: '';
      position: absolute;
      top: 100%;
      left: 20px;
      border-top: 8px solid rgba(0, 0, 0, 0.9);
      border-left: 8px solid transparent;
      border-right: 8px solid transparent; }
  .package-detail .pck-content .tooltip-wrap {
    position: relative;
    cursor: pointer; }
    .package-detail .pck-content .tooltip-wrap:hover .tooltip-item {
      display: block; }
  @media screen and (min-width: 768px) {
    .package-detail .pck-content .mobile {
      display: none; } }
  .package-detail .pck-content .slide-package {
    padding-bottom: 60px; }
    .package-detail .pck-content .slide-package .pck-column {
      border-right: none !important;
      border-left: none !important; }
    .package-detail .pck-content .slide-package .pck-list {
      min-height: 320px; }
    .package-detail .pck-content .slide-package .carousel-indicators li {
      width: 8px;
      height: 8px;
      background-color: #A2A2A2;
      border-radius: 50%; }
      .package-detail .pck-content .slide-package .carousel-indicators li.active {
        background-color: #307FE2; }
  .package-detail .pck-content .carousel-control-next, .package-detail .pck-content .carousel-control-prev {
    bottom: 0;
    width: 100px;
    top: unset !important;
    opacity: .7 !important;
    cursor: pointer; }
    .package-detail .pck-content .carousel-control-next img, .package-detail .pck-content .carousel-control-prev img {
      width: 100%; }
      .package-detail .pck-content .carousel-control-next img.next-btn, .package-detail .pck-content .carousel-control-prev img.next-btn {
        transform: rotate(180deg); }
  .package-detail .get-in-touch-area {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 15px;
    margin-top: 20px;
    display: flex;
    flex-direction: column; }
    .package-detail .get-in-touch-area .get-in-touch-des {
      font-size: 0.875rem;
      color: #808080; }
    .package-detail .get-in-touch-area .get-in-touch-content {
      flex-direction: column; }
      @media screen and (min-width: 768px) {
        .package-detail .get-in-touch-area .get-in-touch-content {
          flex-direction: row;
          align-items: center; } }
    .package-detail .get-in-touch-area h3 {
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 700; }
    @media screen and (min-width: 768px) {
      .package-detail .get-in-touch-area .actions {
        width: 200px;
        margin-left: 20px; } }
    .package-detail .get-in-touch-area .actions .btn {
      max-width: 9.75rem;
      font-size: 0.75rem;
      background-color: #307FE2;
      text-align: center; }
  .package-detail .pck-footer {
    margin-top: 1.875rem;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .package-detail .pck-footer.pck-footer-payment {
      justify-content: flex-end; }
    .package-detail .pck-footer .btn-cancel {
      flex-grow: 1;
      align-items: flex-end;
      display: flex;
      color: #4A4A4A;
      font-size: 0.875rem;
      cursor: pointer; }
    .package-detail .pck-footer .btn-update {
      width: 344px;
      height: 56px;
      border: 1px solid transparent;
      color: #000000;
      background-color: #E2E8EE;
      border-color: #939DA5;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.875rem; }
      .package-detail .pck-footer .btn-update:hover {
        color: #000000;
        background-color: #b2c2d2 !important;
        border-color: #939DA5; }
      .package-detail .pck-footer .btn-update:focus {
        box-shadow: #b2c2d2; }
      .package-detail .pck-footer .btn-update.active {
        background-color: #307FE2;
        color: #fff;
        border: none; }
        .package-detail .pck-footer .btn-update.active:hover {
          color: #fff;
          background-color: #185aad !important; }
      @media screen and (max-width: 411px) {
        .package-detail .pck-footer .btn-update {
          height: 40px; } }
      @media screen and (max-width: 768px) {
        .package-detail .pck-footer .btn-update {
          width: 50%; } }
    .package-detail .pck-footer .btn-change-plan {
      padding-right: 2rem;
      font-size: 0.875rem;
      color: #307FE2;
      font-weight: 900; }
      .package-detail .pck-footer .btn-change-plan img {
        margin-right: 1rem; }
  .package-detail .promo {
    width: 250px; }
    .package-detail .promo .form-control {
      background: #ececec; }
    .package-detail .promo .text-danger {
      color: #ff0000;
      font-size: 0.75rem; }
