.zapier_container .title {
  font-size: 18px;
  font-weight: 700; }

.zapier_container .subtitle {
  font-size: 14px;
  font-weight: 400;
  color: #69787f; }

.zapier_container .image-box-100w {
  padding: 20px;
  width: 100%;
  background: #fffdf9;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px; }

.zapier_container .form-box {
  padding: 20px;
  margin-top: 20px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700; }

.zapier_container .tooltip-container {
  position: relative;
  display: inline-block;
  /* Show tooltip on hover over the container */ }
  .zapier_container .tooltip-container .copy-button {
    padding: 8px 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer; }
  .zapier_container .tooltip-container .tooltip-box {
    position: absolute;
    top: 70%;
    /* Tooltip appears directly below the button */
    left: 80%;
    margin-top: 8px;
    /* Space between button and tooltip */
    padding: 4px 8px;
    background-color: #fff;
    color: 69787f;
    border-color: '#E2E8EE';
    border-width: '1px';
    border-style: 'solid';
    border-radius: 8px;
    font-size: 12px;
    white-space: nowrap;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
    /* Hidden by default */
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease, visibility 0.2s ease; }
  .zapier_container .tooltip-container:hover .tooltip-box {
    opacity: 1;
    visibility: visible; }
