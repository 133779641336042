#tooltip-upgrade.tooltip.show {
  opacity: 1; }

#tooltip-upgrade .tooltip-inner {
  background-color: #fff;
  color: #000;
  max-width: 22rem;
  padding: 1.7rem;
  text-align: left;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  display: flex;
  flex-direction: column; }
  #tooltip-upgrade .tooltip-inner img {
    margin-bottom: 0.5rem;
    margin-right: 1rem; }
  #tooltip-upgrade .tooltip-inner h5 {
    display: inline-block; }

#tooltip-upgrade .arrow::before {
  border-top-color: #fff;
  border-width: 1rem 1rem 0; }

#tooltip-upgrade a {
  font-weight: bold; }
