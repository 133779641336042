.quickbook-button .qb-connect-checkbox {
  display: none; }

.quickbook-button .qb-connect-checker {
  background-image: url("/assets/images/qb-disconnected.png");
  background-size: auto 100%;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-bottom: 0px; }

.quickbook-button .qb-connect-checkbox:checked + .qb-connect-checker {
  background-image: url("/assets/images/qb-connected.png"); }
