.layout-default main {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important; }

.invoice-detail-page {
  color: #353535; }
  .invoice-detail-page .bread-crumbs {
    margin-bottom: 1.5rem; }
    .invoice-detail-page .bread-crumbs h4 {
      font-size: 0.875rem;
      font-weight: bold;
      margin: 0; }
      .invoice-detail-page .bread-crumbs h4 .date-created {
        font-weight: 500; }
  .invoice-detail-page .summary {
    display: flex;
    flex-wrap: wrap; }
    .invoice-detail-page .summary .summary-item {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      width: 100%; }
      @media only screen and (min-width: 480px) {
        .invoice-detail-page .summary .summary-item {
          width: 33.33%; } }
      .invoice-detail-page .summary .summary-item .group-resend .resend {
        color: #0B65E0;
        font-weight: bold;
        margin: 0 1rem;
        text-transform: uppercase; }
      .invoice-detail-page .summary .summary-item .btn-credit-card {
        background-color: #28A038;
        border-color: #979797;
        color: #F9FAFB;
        height: 32px;
        display: inline-block;
        line-height: 32px;
        line-height: 22.4px;
        position: relative;
        text-align: center;
        min-width: 254px;
        border-radius: 20px;
        margin-top: 1rem; }
        .invoice-detail-page .summary .summary-item .btn-credit-card img {
          position: absolute;
          right: 4px;
          top: 4px; }
    .invoice-detail-page .summary .summary-primary h1, .invoice-detail-page .summary .summary-primary p {
      margin: 0; }
    .invoice-detail-page .summary .summary-primary .sub-summary {
      font-size: 1rem; }
    .invoice-detail-page .summary .summary-primary .total {
      text-align: center;
      font-size: 1rem;
      font-weight: 400;
      height: 2rem;
      line-height: 2rem;
      letter-spacing: 0.2933333px;
      color: #353535; }
    .invoice-detail-page .summary .summary-primary .payment-status {
      text-transform: capitalize;
      width: 174px;
      border-radius: 23.5px;
      height: 2rem;
      line-height: 2rem;
      color: #F9FAFB;
      text-align: center; }
      .invoice-detail-page .summary .summary-primary .payment-status.partially-paid {
        position: relative;
        background-color: #E87B04; }
        .invoice-detail-page .summary .summary-primary .payment-status.partially-paid:after {
          content: '';
          position: absolute;
          background: url("/assets/images/icons/partial.svg");
          top: 3px;
          right: 3px;
          width: 24px;
          height: 24px; }
      .invoice-detail-page .summary .summary-primary .payment-status.paid {
        background-color: #28A038;
        position: relative; }
        .invoice-detail-page .summary .summary-primary .payment-status.paid:after {
          content: '';
          position: absolute;
          background: url("/assets/images/icons/paid.svg");
          top: 3px;
          right: 3px;
          width: 24px;
          height: 24px; }
    .invoice-detail-page .summary .summary-contact {
      align-self: center;
      padding-left: 2.125rem;
      padding-right: 2.125rem;
      display: flex;
      flex-direction: column;
      border-left: none; }
      @media only screen and (min-width: 480px) {
        .invoice-detail-page .summary .summary-contact {
          border-left: 1px solid #dee2e6 !important; } }
      .invoice-detail-page .summary .summary-contact .icon {
        margin-right: 5px; }
      .invoice-detail-page .summary .summary-contact .text-sub {
        font-size: 0.75rem; }
    .invoice-detail-page .summary .summary-pay-invoice {
      align-self: center; }
      .invoice-detail-page .summary .summary-pay-invoice .btn-pay-invoice {
        width: 100%;
        height: 3rem;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 0.875rem; }
      .invoice-detail-page .summary .summary-pay-invoice .printInvoiceContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center; }
        .invoice-detail-page .summary .summary-pay-invoice .printInvoiceContainer img {
          object-fit: contain;
          margin-left: 1rem; }
      .invoice-detail-page .summary .summary-pay-invoice .btn-print {
        color: #307FE2;
        text-align: center;
        padding-top: 1rem;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: bold; }

.modal-long-content {
  padding: 20px !important; }
  .modal-long-content .modal-troubleshooting-email-body {
    font-style: 'Source Sans Pro'; }
    .modal-long-content .modal-troubleshooting-email-body .title {
      text-align: center;
      font-weight: 900;
      font-size: 16px;
      margin: 1rem 0 2.5rem; }
    .modal-long-content .modal-troubleshooting-email-body ul {
      font-weight: 400;
      font-size: 14px;
      line-height: 30px; }
      .modal-long-content .modal-troubleshooting-email-body ul li {
        margin: 1rem 0; }
      .modal-long-content .modal-troubleshooting-email-body ul li:nth-child(2), .modal-long-content .modal-troubleshooting-email-body ul li:nth-child(6) {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px; }
