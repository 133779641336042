.alert-form {
  background: #ececec;
  padding: 1.25rem 0;
  border-radius: 0.25rem; }
  .alert-form .alert-form__header {
    display: flex;
    justify-content: space-between; }
    .alert-form .alert-form__header .form-group {
      padding: 0 0.375rem;
      margin-bottom: 0; }
    .alert-form .alert-form__header .alert-form__subtitle {
      color: rgba(0, 0, 0, 0.5);
      font-size: 0.875rem;
      margin-bottom: 0; }
    .alert-form .alert-form__header .alert-form__toggle {
      margin-right: 1rem; }
    .alert-form .alert-form__header .form-toggle {
      flex-direction: column;
      background-color: transparent; }
      @media screen and (min-width: 600px) {
        .alert-form .alert-form__header .form-toggle {
          flex-direction: row; } }
      .alert-form .alert-form__header .form-toggle label {
        color: rgba(0, 0, 0, 0.5);
        font-size: 0.875rem;
        margin-bottom: 0; }
      .alert-form .alert-form__header .form-toggle .auto-send {
        margin-bottom: 0.25rem;
        margin-right: 0; }
        @media screen and (min-width: 600px) {
          .alert-form .alert-form__header .form-toggle .auto-send {
            margin-bottom: 0;
            margin-right: 0.75rem; } }
  .alert-form .alert-form__title {
    display: flex;
    align-items: start; }
    .alert-form .alert-form__title .bubble-icon {
      width: 3.5rem;
      text-align: center; }
    .alert-form .alert-form__title .alert-form__title--text {
      padding-left: 0;
      background-color: transparent; }
    .alert-form .alert-form__title .form-group {
      width: 130px;
      margin-bottom: 0; }
    .alert-form .alert-form__title .form-group .form-control {
      height: 24px;
      line-height: 1rem; }
  .alert-form .alert-form__toggle {
    align-items: flex-end; }
    @media screen and (min-width: 600px) {
      .alert-form .alert-form__toggle {
        align-items: center; } }
    .alert-form .alert-form__toggle .form-toggle {
      padding-right: 0; }
      .alert-form .alert-form__toggle .form-toggle .switch {
        margin-right: 0; }
  .alert-form .alert-form__options {
    display: flex;
    justify-content: space-between;
    margin-bottom: .5rem;
    padding: 0 1rem; }
    @media screen and (min-width: 600px) {
      .alert-form .alert-form__options {
        padding: 0 3.5rem; } }
    .alert-form .alert-form__options .form-group {
      background-color: transparent;
      margin-bottom: 0;
      padding: 0.5rem 0; }
  .alert-form .alert-form__message {
    display: flex;
    flex-direction: column;
    align-items: inherit;
    margin-left: 1rem;
    margin-right: 1rem; }
    @media screen and (min-width: 600px) {
      .alert-form .alert-form__message {
        flex-direction: row;
        align-items: flex-end;
        margin-left: 3.5rem;
        margin-right: 0; } }
    .alert-form .alert-form__message .alert-form__message-input {
      flex-basis: 100%;
      background-color: #E0E0E0;
      border-radius: 0.25rem; }
      @media screen and (min-width: 600px) {
        .alert-form .alert-form__message .alert-form__message-input {
          flex-basis: calc(100% - 3.5rem); } }
      .alert-form .alert-form__message .alert-form__message-input textarea {
        resize: none; }
    .alert-form .alert-form__message .alert-form__message-actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0.5rem; }
      @media screen and (min-width: 600px) {
        .alert-form .alert-form__message .alert-form__message-actions {
          flex-direction: row; } }
      .alert-form .alert-form__message .alert-form__message-actions .alert-form__message-action-btn {
        padding: 0.5rem; }
      .alert-form .alert-form__message .alert-form__message-actions .paste-btn {
        display: flex;
        align-items: center;
        font-size: 0.75rem;
        font-weight: 700;
        color: #307FE2; }
    .alert-form .alert-form__message .form-group {
      padding: 1rem;
      margin-bottom: 0;
      background-color: transparent; }
      .alert-form .alert-form__message .form-group .form-control {
        padding: 0; }
  .alert-form .alert-form__trash-btn {
    width: 100%; }
    @media screen and (min-width: 600px) {
      .alert-form .alert-form__trash-btn {
        width: 3.5rem; } }
    .alert-form .alert-form__trash-btn .trash-btn {
      justify-content: center;
      display: flex;
      width: 100%;
      margin-top: 1rem; }
