.modal-reject {
  text-align: center;
  margin: 0;
  z-index: 2000; }
  @media (min-width: 576px) {
    .modal-reject {
      max-width: 500px;
      margin: 0 !important; } }
  .modal-reject .modal-content {
    border: none; }
    .modal-reject .modal-content .modal-header {
      display: block; }
    .modal-reject .modal-content .modal-body .wrapper {
      display: flex;
      flex-direction: column;
      align-items: center; }
  .modal-reject .closeIcon {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 99;
    cursor: pointer; }
  .modal-reject .title {
    margin-top: 1rem;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.35); }

@media screen and (max-width: 600px) {
  .modal {
    max-width: 500px !important; } }
