.modal-add-service-checklists {
  position: fixed;
  top: 6.25rem;
  right: -31.214rem;
  width: 31.214rem;
  height: calc(100vh - 6.25rem);
  transition: all 0.35s ease-in;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.05);
  z-index: 1080; }
  @media screen and (max-width: 600px) {
    .modal-add-service-checklists {
      top: 0;
      right: -100vw;
      width: 100vw;
      height: 100vh; } }
  .modal-add-service-checklists .btn-close {
    position: fixed;
    top: 6.25rem;
    right: -5rem;
    color: #ffffff;
    padding: 1.25rem 1.75rem 1.375rem;
    transition: all 0.35s ease-in;
    z-index: 1080; }
    .modal-add-service-checklists .btn-close .icon {
      width: 0.875rem; }
    @media screen and (max-width: 600px) {
      .modal-add-service-checklists .btn-close {
        top: unset;
        bottom: 5rem; } }
  .modal-add-service-checklists.show {
    right: 0; }
    .modal-add-service-checklists.show .btn-close {
      right: 31.214rem; }
      @media screen and (max-width: 600px) {
        .modal-add-service-checklists.show .btn-close {
          right: 1rem; } }
  .modal-add-service-checklists .block-content {
    min-height: calc(100vh - 6.25rem);
    background-color: #ffffff;
    padding: 1.25rem; }
    @media screen and (max-width: 600px) {
      .modal-add-service-checklists .block-content {
        height: 100vh;
        margin-left: unset; } }
    .modal-add-service-checklists .block-content .service-box select {
      padding: 0 0.875rem; }
    .modal-add-service-checklists .block-content .nav-tabs {
      padding-bottom: 1.5rem; }
      .modal-add-service-checklists .block-content .nav-tabs .nav-item .nav-link {
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.3);
        background-color: rgba(105, 105, 105, 0.12);
        font-size: 0.875rem;
        border: 0.25rem solid rgba(105, 105, 105, 0);
        border-radius: unset;
        padding: 0.5rem; }
        .modal-add-service-checklists .block-content .nav-tabs .nav-item .nav-link.active {
          color: #000000;
          background-color: #ffffff;
          font-weight: 700;
          border-color: rgba(105, 105, 105, 0.12); }
    .modal-add-service-checklists .block-content .scroll-area {
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      padding-bottom: 1rem;
      overflow-x: hidden;
      max-height: calc(100vh - 24rem); }
      .modal-add-service-checklists .block-content .scroll-area .task-wrapper {
        background: #ECECEC;
        border-radius: 3px;
        margin-bottom: 12px; }
        .modal-add-service-checklists .block-content .scroll-area .task-wrapper .form-group {
          width: 100%;
          margin-bottom: 0;
          background-color: #ECECEC; }
          .modal-add-service-checklists .block-content .scroll-area .task-wrapper .form-group:focus-within {
            background: #FFFFFF; }
            .modal-add-service-checklists .block-content .scroll-area .task-wrapper .form-group:focus-within ~ img {
              display: none; }
    .modal-add-service-checklists .block-content button {
      font-size: 0.75rem;
      text-transform: uppercase;
      letter-spacing: 0.0625rem;
      border-radius: 0.125rem;
      padding: 1rem 1.75rem;
      margin-right: 0.5rem; }

.group-button .save {
  background-color: #307FE2;
  color: #fff; }

.add-btn {
  display: flex;
  justify-content: flex-start;
  font-weight: 700;
  color: #307FE2;
  align-items: center; }

.action-checklist {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center; }
