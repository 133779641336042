.image-company-logo {
  width: 200px;
  height: 80px;
  margin-right: 2.5rem;
  border-radius: unset;
  overflow: 'hidden'; }
  @media screen and (max-width: 768px) {
    .image-company-logo {
      width: 78px;
      height: 78px;
      margin-right: 0;
      border-radius: 50%; } }

.approve-or-reject {
  width: 50%;
  margin: 1rem auto;
  display: flex;
  flex-direction: row; }
  @media screen and (max-width: 992px) {
    .approve-or-reject {
      margin: 0 auto;
      width: 70%;
      flex-direction: column; } }

.play-media-overlay {
  background-color: rgba(196, 196, 196, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }

.text-reject-vehicle {
  color: #8C8C8F;
  margin-right: 0.5rem !important; }

.text-btn-change-status {
  font-weight: 700 !important;
  font-size: 0.75rem;
  margin-right: 0.5rem !important; }

.text-btn-change-status {
  font-weight: 700 !important; }

.button-approved-reject {
  padding: 0;
  margin: auto; }
  @media screen and (min-width: 768px) {
    .button-approved-reject {
      padding: 1rem;
      font-weight: 1rem; } }

.button-approved-reject-right {
  padding: 0;
  margin: auto; }
  @media screen and (min-width: 768px) {
    .button-approved-reject-right {
      padding: 1rem;
      font-weight: 1rem;
      margin: 0; } }

.text-deposit-due {
  color: #E80604; }

.text-deposit-paid {
  color: #0A891B; }

.reject-vehicle {
  background-color: #FF0000;
  height: 8px;
  width: 100%; }

.approved-vehicle {
  background-color: #28A038;
  height: 8px;
  width: 100%; }

.btn-reject {
  display: none; }
  @media screen and (min-width: 768px) {
    .btn-reject {
      display: flex;
      position: relative;
      z-index: 2;
      background-color: #FF0000;
      padding: 0.25rem 1.25rem;
      color: white;
      border-radius: 24px;
      opacity: 1 !important;
      text-align: center;
      width: fit-content;
      margin-left: auto;
      margin-bottom: 0.25rem; } }

.btn-reject-mobile {
  display: flex;
  margin-left: auto;
  position: relative;
  z-index: 2;
  background-color: #FF0000;
  padding: 0.25rem 1.25rem;
  color: white;
  border-radius: 24px;
  opacity: 1 !important;
  text-align: center;
  width: fit-content;
  margin-bottom: 0.25rem; }
  @media screen and (min-width: 768px) {
    .btn-reject-mobile {
      display: none; } }

.btn-approved {
  display: none; }
  @media screen and (min-width: 768px) {
    .btn-approved {
      display: flex;
      background-color: #28A038;
      padding: 0.25rem 1.25rem;
      color: white;
      border-radius: 24px;
      opacity: 1 !important;
      text-align: center;
      margin-left: auto;
      margin-bottom: 0.25rem;
      width: fit-content; } }

.btn-approved-mobile {
  display: flex;
  margin-left: auto;
  background-color: #28A038;
  padding: 0.25rem 1.25rem;
  color: white;
  border-radius: 24px;
  opacity: 1 !important;
  text-align: center;
  width: fit-content;
  margin-bottom: 0.25rem; }
  @media screen and (min-width: 768px) {
    .btn-approved-mobile {
      display: none; } }

.group-btn-download-document-mobile {
  display: flex;
  flex-direction: row; }
  @media screen and (min-width: 768px) {
    .group-btn-download-document-mobile {
      display: none; } }

.group-btn-download-document-desktop {
  display: none; }
  @media screen and (min-width: 768px) {
    .group-btn-download-document-desktop {
      display: flex; } }

.group-button-download-pdf {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem; }
  @media screen and (min-width: 768px) {
    .group-button-download-pdf {
      margin-top: 0;
      margin-bottom: 0; } }
  .group-button-download-pdf .btn-download-document {
    color: #307FE2 !important;
    padding: 0 !important;
    text-transform: uppercase;
    font-weight: 700 !important;
    font-size: .75em !important;
    margin-left: 0;
    cursor: pointer; }
    @media screen and (min-width: 768px) {
      .group-button-download-pdf .btn-download-document {
        margin-left: auto; } }

.blur {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: white;
  opacity: 0.5; }

.service-desktop .total {
  font-size: 0.75rem;
  color: #8C8C8F; }

.estimate-detail-company-info {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column; }
  @media screen and (min-width: 768px) {
    .estimate-detail-company-info {
      flex-direction: row;
      justify-content: left; } }
  .estimate-detail-company-info .text-info-center {
    text-align: center; }
    @media screen and (min-width: 768px) {
      .estimate-detail-company-info .text-info-center {
        text-align: left; } }

.estimate-detail-info {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column; }
  @media screen and (min-width: 768px) {
    .estimate-detail-info {
      flex-direction: row;
      justify-content: left; } }
  .estimate-detail-info .text-info-center {
    text-align: center; }
    @media screen and (min-width: 768px) {
      .estimate-detail-info .text-info-center {
        text-align: left; } }
  .estimate-detail-info .border-start {
    border-left: 0px solid #dee2e6 !important; }
    @media screen and (min-width: 768px) {
      .estimate-detail-info .border-start {
        border-left: 1px solid #dee2e6 !important; } }
  .estimate-detail-info .estimate-info-detail {
    flex-direction: column; }
    @media screen and (min-width: 768px) {
      .estimate-detail-info .estimate-info-detail {
        flex-direction: row-reverse;
        display: flex;
        width: 100%;
        align-items: center; } }
  .estimate-detail-info .estimate-user-info {
    display: block;
    font-size: .875em;
    text-align: center; }
    @media screen and (min-width: 768px) {
      .estimate-detail-info .estimate-user-info {
        text-align: end; } }
  .estimate-detail-info .estimate-detail-total-info {
    display: flex;
    flex-direction: column-reverse; }
    @media screen and (min-width: 768px) {
      .estimate-detail-info .estimate-detail-total-info {
        flex-direction: column; } }
  .estimate-detail-info .estimate-detail-total-value {
    font-size: calc(1.35rem + 1.5vw);
    font-weight: 500;
    line-height: 1.2;
    margin-top: 1rem; }
    @media screen and (min-width: 768px) {
      .estimate-detail-info .estimate-detail-total-value {
        font-weight: 400;
        font-size: 1rem;
        margin-top: 0.5rem; } }
  .estimate-detail-info .estimate-detail-total-title {
    font-size: 1rem;
    font-weight: 400; }
    @media screen and (min-width: 768px) {
      .estimate-detail-info .estimate-detail-total-title {
        font-weight: bolder; } }
  .estimate-detail-info .estiamte-detail-deposit {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    align-items: center;
    display: flex;
    color: #E80604;
    font-weight: bolder;
    margin: auto; }
    @media screen and (min-width: 768px) {
      .estimate-detail-info .estiamte-detail-deposit {
        margin: 0; } }

.estimate-detail-vehicle-info .estimate-detail-vehicle-status {
  text-align: end;
  display: flex;
  flex-direction: column-reverse;
  align-items: space-between;
  height: 100%; }
  @media screen and (min-width: 768px) {
    .estimate-detail-vehicle-info .estimate-detail-vehicle-status {
      flex-direction: column; } }

.estimate-detail-vehicle-info .estimate-detail-vehicle-image {
  object-fit: contain;
  width: 20%; }
  @media screen and (min-width: 768px) {
    .estimate-detail-vehicle-info .estimate-detail-vehicle-image {
      width: 200px;
      height: 104px;
      object-fit: contain; } }

.estimate-detail-vehicle-info .estimate-detail-vehicle-name {
  font-size: 1rem;
  font-weight: 700; }
  @media screen and (min-width: 768px) {
    .estimate-detail-vehicle-info .estimate-detail-vehicle-name {
      font-size: 1.25rem; } }

.estimate-detail-vehicle-service {
  display: grid;
  grid-auto-columns: 1fr;
  grid-gap: 0.5rem;
  grid-template-areas: "item"; }
  @media screen and (min-width: 768px) {
    .estimate-detail-vehicle-service {
      grid-template-areas: "item item item"; } }

.estimate-detail-group-button {
  padding-top: 1rem;
  padding-right: 2rem;
  margin-left: auto;
  margin-right: auto; }
  @media screen and (min-width: 768px) {
    .estimate-detail-group-button {
      padding-top: 0;
      margin-right: 0; } }
  @media screen and (max-width: 768px) {
    .estimate-detail-group-button {
      padding-right: 0; } }
  .estimate-detail-group-button .status-approve {
    padding: 0.5rem 2.5rem; }
    @media screen and (min-width: 768px) and (max-width: 992px) {
      .estimate-detail-group-button .status-approve {
        padding: 0.5rem 1rem; } }
  .estimate-detail-group-button .btn-approve {
    padding: 1rem 6rem; }
    @media screen and (min-width: 768px) and (max-width: 992px) {
      .estimate-detail-group-button .btn-approve {
        padding: 1rem 1rem; } }

.estimate-detail-vehicle-media {
  display: grid;
  grid-template-areas: "item item item item";
  grid-auto-columns: 1fr;
  box-sizing: border-box;
  grid-gap: 0.5rem; }
  @media screen and (min-width: 768px) {
    .estimate-detail-vehicle-media {
      padding: 1.5rem;
      grid-gap: 1.5rem;
      grid-auto-columns: 1fr; } }
  .estimate-detail-vehicle-media .media-container {
    position: relative;
    min-height: auto; }
    @media screen and (min-width: 768px) {
      .estimate-detail-vehicle-media .media-container {
        min-height: 200px; } }

.grid-template-areas-3 {
  grid-template-areas: "item item item"; }

.grid-gap-2 {
  grid-gap: 0.5rem; }

.d-grid {
  display: grid !important; }

.border-start {
  border-left: 1px solid #dee2e6 !important; }

.border-end {
  border-right: 0px solid #dee2e6 !important; }
  @media screen and (min-width: 768px) {
    .border-end {
      border-right: 1px solid #dee2e6 !important; } }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.fw-bold {
  font-weight: 700 !important; }

.px-3 {
  padding-left: 1rem;
  padding-right: 1rem; }

.ps-4 {
  padding-left: 0rem; }
  @media screen and (min-width: 768px) {
    .ps-4 {
      padding-left: 1.5rem; } }

.pe-2 {
  padding-right: 0.5rem; }

.pe-4 {
  padding-right: 0rem; }
  @media screen and (min-width: 768px) {
    .pe-4 {
      padding-right: 1.5rem; } }

.text-end {
  text-align: right; }

.fs-small {
  font-size: .875rem !important; }

.pe-3 {
  padding-right: 1rem; }
  @media screen and (min-width: 768px) {
    .pe-3 {
      padding-right: 1rem; } }

.me-2 {
  margin-right: 0.5rem; }

.d-block {
  display: block; }

.fs-base {
  font-size: 1rem; }

.fs-sub {
  font-size: .75em !important; }

.ms-auto {
  margin-left: auto; }

.text-start {
  text-align: left !important; }

.w-100 {
  width: 100% !important; }

.grid-auto-columns {
  grid-auto-columns: 1fr; }

.grid-gap-4 {
  grid-gap: 1.5rem; }

.grid-template-areas-4 {
  grid-template-areas: "item item item item"; }

.ms-2 {
  margin-left: 0.5rem; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.rounded {
  border-radius: 0.25rem !important; }
