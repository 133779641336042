.disclosure__container .title {
  font-size: 0.75rem;
  font-weight: 700; }

.disclosure__container .subtitle {
  font-size: 0.875rem;
  font-weight: 400;
  color: #6C6C6C; }

.disclosure__container .disabled {
  opacity: 0.25;
  pointer-events: none; }

.disclosure__container .disclosure {
  background: #ECECEC;
  border-radius: 3px; }
  .disclosure__container .disclosure .form-group {
    padding: 0.75rem 0.875rem;
    background: #ECECEC;
    margin-bottom: 0; }
    .disclosure__container .disclosure .form-group:focus-within {
      background: #FFFFFF;
      outline: none; }
    .disclosure__container .disclosure .form-group .form-label {
      color: #929292;
      font-size: 0.75rem;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: unset; }
    .disclosure__container .disclosure .form-group .form-control {
      color: black;
      background: transparent;
      border: unset;
      border-radius: unset;
      padding-left: unset;
      padding-right: unset; }
      .disclosure__container .disclosure .form-group .form-control:focus-within {
        background: #FFFFFF;
        outline: none; }
    .disclosure__container .disclosure .form-group textarea {
      min-height: 45px;
      height: 100%;
      resize: vertical; }

.disclosure__container .border__error {
  border-radius: 3px;
  border: 1px solid red !important; }

.disclosure__container .disclosure__action {
  display: flex;
  justify-content: end;
  align-items: center; }
  .disclosure__container .disclosure__action .font-semibold {
    font-weight: 600; }
  .disclosure__container .disclosure__action .btn-delete {
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    font-size: 0.875rem; }

.disclosure__container .btn-submit {
  font-size: 0.8rem;
  font-weight: bold;
  padding: 1rem 3.5rem; }

.font-semibold {
  font-weight: 600; }
