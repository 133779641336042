.list-vehicle-info .vehicle-info {
  font-size: .875rem;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.list-vehicle-info .image-vehicle {
  object-fit: contain; }

.list-vehicle-info .item-more {
  background-color: #fff;
  position: absolute;
  top: -30%;
  left: 0;
  right: 0;
  z-index: 200; }

.list-vehicle-info .text-title {
  font-weight: bold; }

.list-vehicle-info .btn-more {
  width: 30%;
  padding: 0;
  text-align: left;
  font-size: 10px;
  letter-spacing: 1px;
  line-height: 12.57px;
  font-weight: bold; }

.list-vehicle-info .year-vehicle {
  border-right: 1px solid #eaeaea;
  padding-right: 0.5rem; }

.list-vehicle-info .color-vehicle {
  border-left: 1px solid #eaeaea;
  padding-left: 0.5rem; }
